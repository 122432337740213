jQuery(document).ready(function($){ 

    var isMobile = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
        }
    };

    /* ---------------------------------------------
     Logo slider
     --------------------------------------------- */

	var searchResultSlideInProgress = false;
	
    var logoSlider = $('.logo-slider');
    var logoSlides = $('.logo-slides');

    $('.logo-slides-wrapper .right-arrow').click(function(){
		if (searchResultSlideInProgress == false)
		{
			searchResultSlideInProgress = true;
            logoSlider.addClass('active');
			
            setTimeout(function(){
				logoSlides.find('li:first-child').appendTo(logoSlides);
                logoSlider.removeClass('active');

                searchResultSlideInProgress = false;
			}, 600);
		}
	});

	slideShow_interval = setInterval(function(){$('.logo-slides-wrapper .right-arrow').trigger('click');}, 1300);	
	$('.logo-slides-wrapper').mouseenter(function(){
		clearInterval(slideShow_interval);}).mouseleave(function() {slideShow_interval = setInterval(function(){$('.logo-slides-wrapper .right-arrow').trigger('click');}, 1300);
	});

    /* ---------------------------------------------
     preloading images
     --------------------------------------------- */
    var images = $('img');
        $('.cover-image').each(function() {
            var el = $(this),
                image = el.css('background-image').match(/url\((['"])?(.*?)\1\)/);
            if (image)
                images = images.add($('<img>').attr('src', image.pop()));
        });
        if ($("[data-inlinesvg]").length) {
            neopix.inlinesvg.init();
        }
        var imageCount = images.length;
        var imageCounter = 0;
        images.imagesLoaded(function() {
            setTimeout(function() {
                if (typeof WOW === 'function') {
                    wow = new WOW({
                        animateClass: 'animated',
                        offset: 150,
                        live: true,
                        mobile: false
                    });
                    wow.init();
                }
                $(".page-loader div").fadeOut();
                $(".page-loader").delay(200).fadeOut("slow");
            }, 400);
        }).progress(function(instance, image) {
            var result = image.isLoaded ? 'loaded' : 'broken';
            imageCounter = imageCounter + 1;
            //console.log(imageCounter + '/' + imageCount + ' image is ' + result + ' for ' + image.img.src);
            $('.splashProgress').css('height', (imageCount - imageCounter) * 100 / imageCount + '%');
        }).always(function(instance) {
            console.log('all images loaded');
        });

    /* ---------------------------------------------
     On resize
     --------------------------------------------- */

    $( window ).resize(function() {
        var width = $(this).width();
        var height = $(this).height();

        if (width > 767) {
            logoSlides.find('li').removeAttr('style');
        } else if (width > 579) {
            logoSlides.find('li').css('width', width / 4);
        } else if (width > 439) {
            logoSlides.find('li').css('width', width / 3);
        } else {
            logoSlides.find('li').css('width', width / 2);
        }

        $(".features-content").css('height', $(".features-content li.active").not(".moveout").height());

        if (width > 767) {
            if (height > 600) {
                $('#app-cover').css('height', '100%');
            } else {
                $('#app-cover').css('height', '600px');
            }
        } else {
            $('#app-cover').css('height', '500px');
            $('#bus-cover').css('height', '500px');
        }
        if (width > 767 && width < 1440) {
            if (height > 720) {
                $('#bus-cover').css('height', '100%');
            } else {
                $('#bus-cover').css('height', '720px');
            }
        }
        if (width > 1440) {
            if ( height < 860) {
                $('#bus-cover').css('height', '900px');
            }
            else {
                $('#bus-cover').css('height', '100%');
            }
        }
        

    }).trigger('resize');

    /* ---------------------------------------------
     On scroll
     --------------------------------------------- */

    $( window ).scroll(function() {

        $(".features-content").css('height', $(".features-content li.active").not(".moveout").height());
    });

    /* ---------------------------------------------
     menu
     --------------------------------------------- */

    $('.mobile-menu').click(function(){
        $('body').addClass('open');
    });
    $('.mobile-menu-close, .menu-sec-nav a').click(function(){
        $('body').removeClass('open');
    });

    /* ---------------------------------------------
     pager
     --------------------------------------------- */

    $("#pager a, .menu-sec-nav a, .footer-menu .section-link").on('click', function(e) {
       e.preventDefault();            
       $('html, body').animate({
            scrollTop: $($(this).attr('href')).offset().top
         }, 600, 'easeInOutCubic');
    });



    /* ---------------------------------------------
     features 
     --------------------------------------------- */

    var featuresContentInProgress = false;        
    var featuresList = $(".features-content li");
    var featuresLinks = $(".features-menu a");
    
    $(".features-content").css('height', $(".features-content li:first-child").height());

    featuresLinks.click(function(e){
        e.preventDefault();

        if (featuresContentInProgress == false)
        {
            featuresContentInProgress = true;
            var index = featuresLinks.index($(this));
            var active = featuresLinks.index($('.features-menu a.active'));

            if (index == active) {
                featuresContentInProgress = false;
                return;
            } else if (index < active) {
                featuresList.eq(index).addClass("positionLeft");
            }

            setTimeout(function(){
                featuresList.eq(index).addClass("active");
                if (index < active) {
                    featuresList.eq(active).addClass("moveright");
                } else {
                    featuresList.eq(active).addClass("moveleft");
                }
                featuresContentInProgress = false;
            }, 100);

            featuresLinks.eq(active).removeClass("active");
            featuresLinks.eq(index).addClass("active");

            $(".features-content").css('height', featuresList.eq(index).height());
            
            setTimeout(function(){
                featuresList.eq(index).removeClass("positionLeft");
                featuresList.eq(active).removeClass("active moveleft moveright");
                featuresContentInProgress = false;
            }, 600);
        }
    });

    if ($('.features-content').length > 0 && isMobile.any()) {
        Hammer($('.features-content').get(0)).on("swipeleft", function() {
            var next = featuresLinks.index($('.features-menu a.active')) + 1;

            if (next < featuresLinks.length) {
                featuresLinks.eq(next).trigger('click');
            }
        });
        Hammer($('.features-content').get(0)).on("swiperight", function() {
            var prev = featuresLinks.index($('.features-menu a.active')) - 1;

            if (prev > -1) {
                featuresLinks.eq(prev).trigger('click');
            }
        });
    }



    /* ---------------------------------------------
     testimonial section 
     --------------------------------------------- */

     var partnerTransitionInProgress = false;
     var partnerIndex = -1;

     var makeTransition = function(obj, index, delay){

        heightWrapp = 0;
        height = 0;
            
        if (!obj.hasClass('active')) {
            heightWrapp = obj.find('.testimonial-inner').first().height() + 20;
            height = heightWrapp + 5;
            obj.addClass('active');
            partnerIndex = index;
        } else {
            obj.removeClass('active');
            partnerIndex = -1;
        }

        obj.find('.testimonial-wrapp').css('height', heightWrapp + 'px');
        obj.find('.testimonial').css('height', height + 'px');    
     }

     $(".partners .info").click(function(){

        if (!partnerTransitionInProgress) {
            
            partnerTransitionInProgress = true;

            var partner = $(this).parent();
            var index = partner.index();
            var delay = 0;

            if (partnerIndex > -1 && partnerIndex != index) {

                delay = 400;
                var activePartner = partner.parent().find('.partner:eq(' + partnerIndex + ')');
                
                makeTransition(activePartner, partnerIndex, 0);
                setTimeout(function(){
                    makeTransition(partner, index, delay);
                }, delay);                 
            } else {

                makeTransition(partner, index, 0);                
            }

            setTimeout(function(){
                partnerTransitionInProgress = false;
            }, 500 + delay);        
        }
     });

     $(".testimonial .close-btn").click(function(e){
        e.preventDefault();

        $(this).closest('.partner').find('.info').trigger('click');
     });

     $( window ).resize(function() {
        if (partnerIndex > -1) {
            $('.partners .partner:eq(' + partnerIndex + ') .info').trigger('click');
        }
     });

});